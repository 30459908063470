import { Link } from 'react-router-dom'
import LogoTitleA from '../../assets/images/logo-a.png'
import LogoTitleZ from '../../assets/images/logo-z.png'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Logo from './Logo'
import Loader from 'react-loaders'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const firstNameArray = 'mir '.split('')
  const lastNameArray = 'arandi'.split('')
  const jobArray = 'a developer.'.split('')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
    <div className="contanier home-page">
      <div className="text-zone">
        <h1>
          <span className={letterClass}>H</span>
          <span className={`${letterClass} _12`}>i,</span>
          <br />
          <span className={`${letterClass} _13`}>I</span>
          <span className={`${letterClass} _15`}>'m&nbsp;</span>
          <img src={LogoTitleA} alt="A" />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={firstNameArray}
            idx={16}
          />
          <br />
          <img src={LogoTitleZ} alt="Z" />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={lastNameArray}
            idx={20}
          />
          <br />
          <AnimatedLetters
            letterClass={letterClass}
            strArray={jobArray}
            idx={26}
          />
        </h1>
        <h2>Computer Engineering @ Columbia </h2>
        <Link to="/contact" className="flat-button">
          CONTACT ME
        </Link>
      </div>
      <Logo />
    </div>
    <Loader type="ball-scale-ripple-multiple" />
    </>
  )
}

export default Home
