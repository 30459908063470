import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup} from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCEZFHR5StFnYGoAch5i-gwCdGlsl6w-qg",
  authDomain: "portfolio-dashboard-8219c.firebaseapp.com",
  projectId: "portfolio-dashboard-8219c",
  storageBucket: "portfolio-dashboard-8219c.appspot.com",
  messagingSenderId: "97783278458",
  appId: "1:97783278458:web:2f2e2bd743e04bd0b544c3"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);


export const signInWithGoogle = () => signInWithPopup(auth, provider);