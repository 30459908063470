import { useEffect, useRef } from 'react'
import gsap from 'gsap-trial'
import LogoAZ from '../../../assets/images/logo-az.png'
import './index.scss'

const Logo = () => {
  const bgRef = useRef()
  const outlineLogoRef = useRef()
  const solidLogoRef = useRef()

  useEffect(() => {
    gsap.timeline().to(bgRef.current, {
      duration: 3,
      opacity: 1,
    })

    gsap.fromTo(
      outlineLogoRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 4,
      }
    )

    gsap.fromTo(
      solidLogoRef.current,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 4,
        duration: 4,
      }
    )
  }, [])

  return (
    <div className="logo-container" ref={bgRef}>
      <img
        className="solid-logo"
        ref={solidLogoRef}
        src={LogoAZ}
        alt="JavaScript,  Developer"
      />
      <svg
        className="svg-container"
        width="800"
        height="800"
        version="1.0"
        viewBox="0 0 600 600"
        xmlns="http://www.w3.org/2000/svg"
        zoomAndPan="magnify"
      >
        <path
          ref={outlineLogoRef}
          transform="matrix(1.3828 0 0 1.3828 278.28 -22.859)"
          d="m75.083 298.17h136.42v45.826h-202.51v-45.826l133.95-167.53h-130.34v-45.826h198.19v42.566zm-51.029 33.458-16.653-53.399h-95.612l-18.062 53.399h-57.015l92.349-259.16h62.478l91.292 259.16zm-31.811-98.165-32.074-102.48h-0.70623l-33.221 102.48zm77.51 60.495h136.42v45.826h-202.51v-45.826l133.95-167.53h-130.34v-45.826h198.19v42.566zm-51.026 33.458-16.656-53.402h-95.609l-18.065 53.402h-57.012l92.349-259.16h62.476l91.292 259.16zm-31.811-98.165-32.077-102.48h-0.7034l-33.221 102.48z"
          fill="none"
          stroke="#6096b4"
          strokeWidth="1.6271"
        />
      </svg>
    </div>
  )
}

export default Logo
